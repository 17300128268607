<template>
  <div>
    <c-card title="차트" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <apexchart
            ref="chart1"
            height="250"
            type="bar"
            :width="chart1.chartWidth"
            :options="chart1.chartOptions"
            :series="chart1.series"></apexchart>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <apexchart
            ref="chart2"
            height="250"
            type="bar"
            :width="chart2.chartWidth"
            :options="chart2.chartOptions"
            :series="chart2.series"></apexchart>
        </div>
      </template>
    </c-card>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <c-table
          ref="table"
          :title="'[' + tab.label + '] 개선 목록'"
          tableId="kras01"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
        </c-table>
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'impr-action-rate-detail',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        tab: 'task',
        clickInfo: '',
        data: [],
      }),
    },
  },
  data() {
    return {
      chart1: {
        chartOptions: {
          title: {
            text: '개선처리'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: []
          }
        },
        series: [
          {
            name: "건 수",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      chart2: {
        chartOptions: {
          title: {
            text: '즉시처리'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: []
          }
        },
        series: [
          {
            name: "건 수",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      curTab: 'rqsting',
      tab: 'rqsting',
      tabItems: [
        { name: 'rqsting', icon: 'task_alt', label: '요청중' },
        { name: 'receipting', icon: 'task_alt', label: '접수중' },
        { name: 'actioning', icon: 'task_alt', label: '조치중' },
        { name: 'confirming', icon: 'task_alt', label: '검토중' },
        { name: 'complete', icon: 'task_alt', label: '개선완료' },
        { name: 'immTotal', icon: 'task_alt', label: '즉시처리' },
        // { name: 'immComplete', icon: 'task_alt', label: '즉시처리완료' },
      ],
      tabParam: {
        search: '',
      },
      grid: {
        columns: [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'col1',
            field: 'col1',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'stepNm',
            field: 'stepNm',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'col3',
            field: 'col3',
            label: '요청정보',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '조치기한',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '조치자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setChart();
      this.getList();
    },
    setChart() {
      this.chart1.chartOptions.title.text = '[' + this.popupParam.clickInfo + '] 개선처리'
      this.chart1.chartOptions.xaxis.categories = ['요청중', '접수중', '조치중', '검토중', '개선완료']
      this.chart2.chartOptions.title.text = '[' + this.popupParam.clickInfo + '] 즉시처리'
      this.chart2.chartOptions.xaxis.categories = ['즉시처리건']
      this.chart1.series[0].data = [
        this.popupParam.data.rqsting,
        this.popupParam.data.receipting,
        this.popupParam.data.actioning,
        this.popupParam.data.confirming,
        this.popupParam.data.complete,
      ]
      this.chart2.series[0].data = [
        this.popupParam.data.immTotal,
        // this.popupParam.data.immComplete,
      ]
      setTimeout(() => {
        this.chart1.chartWidth = '100%';
        this.chart2.chartWidth = '100%';
      }, 200);
      this.$refs['chart1'].refresh();
      this.$refs['chart2'].refresh();
    },
    getList() {
      this.grid.data = [
        {
          plantNm: '사업장3',
          col2: this.popupParam.tab === 'task' ? this.popupParam.clickInfo : 'HAZOP',
          col1: '개선 - A',
          stepNm: '접수',
          col3: '안전환경팀/시스템/2021-07-01',
          col4: '2021-08-30',
          col5: '안전환경팀',
          col6: '',
          col7: '2021-08-30',
          col10: 'xxxx에 대해서 조치처리',
          col11: 'xxxx에 대해서 조치처리에 대한 결과',
          col12: 'kkkk 배관',
        },
        {
          plantNm: '사업장3',
          col2: this.popupParam.tab === 'task' ? this.popupParam.clickInfo : 'HAZOP',
          col1: '개선 - B',
          stepNm: '검토',
          col3: '안전환경팀/시스템/2021-07-01',
          col4: '2021-08-30',
          col5: '안전환경팀',
          col6: '홍길동',
          col7: '2021-08-30',
          col10: 'yyyy에 대해서 조치처리',
          col11: 'yyyy에 대해서 조치처리에 대한 결과',
          col12: 'xxx 하수도',
        },
        {
          plantNm: '사업장3',
          col2: this.popupParam.tab === 'task' ? this.popupParam.clickInfo : '변경관리',
          col1: '개선 - C',
          stepNm: '요청',
          col3: '안전환경팀/시스템/2021-07-01',
          col4: '2021-08-30',
          col5: '안전환경팀',
          col6: '',
          col7: '2021-08-30',
          col10: 'zzzz에 대해서 조치처리',
          col11: 'zzzz에 대해서 조치처리에 대한 결과',
          col12: 'kkkk 복도',
        },
      ]
    },
    tabClick(tab) {
      this.curTab = tab.name;
      if (tab.name !== 'immTotal') {
        // 개선처리건일 경우
        this.grid.columns = [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'col1',
            field: 'col1',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'stepNm',
            field: 'stepNm',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'col3',
            field: 'col3',
            label: '요청정보',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '조치기한',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '조치자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ];
      } else {
        // 즉시처리건일 경우
        this.grid.columns = [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'col10',
            field: 'col10',
            label: '조치사항',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'col11',
            field: 'col11',
            label: '조치결과',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'col12',
            field: 'col12',
            label: '위치',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '요청정보',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '조치자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '조치일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ];
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (this.curTab !== 'immTotal') {
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.target = () => import(`${'./imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시개선 상세'; // 즉시개선 상세
        this.popupOptions.target = () => import(`${'./imprImmDetail.vue'}`);
      }
      this.popupOptions.param = row;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
